import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { StyledSelect } from "components";
import { OutlinedPlusIcon, StyledArrowIcon } from "components/Icons";
import { DRAWERS } from "constants/drawer";
import { setPhoneNumberMask } from "helpers/helpers";
import { CartContext } from "pages/CartPage/CartPage";
import { paymentFields, PAY_WITH } from "pages/CartPage/CartPage.constants";
import { MobileContactItem } from "pages/CartPage/components/ContactsBlock/components";
import { array } from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { SelectedCard } from "..";
import { cl } from "../../../../../ProductsPage/components/MobileProductCard/styles";
import { classes } from "./styles";

export const DeliveryCartBlock = ({ creditCards }) => {
  const {
    cartData,
    billingInfo,
    shippingInfo,
    contacts,
    control,
    setSelectedContact,
    selectedContact,
    approvedPaymentTerms,
    formField,
    setValue,
    clearErrors,
    matchOrderDirect,
  } = useContext(CartContext);

  const dispatch = useDispatch();

  const isAdvancePayment = useMemo(
    () => approvedPaymentTerms?.type === "ADVANCE_PAYMENT",
    [approvedPaymentTerms?.type]
  );

  const getSelectPlaceholder = useCallback(
    (value) => {
      if (!contacts?.length)
        return (
          <Box component="span" style={{ color: "rgba(86,86,86,0.50)" }}>
            Select
          </Box>
        );
      const selectedContact = contacts?.find((contact) => contact.id === value);
      return !value ? (
        <Box component="span" style={{ color: "rgba(86,86,86,0.50)" }}>
          Select
        </Box>
      ) : (
        selectedContact?.name
      );
    },
    [contacts]
  );

  const hidePayWithCredit = useMemo(() => {
    return !(
      !!matchOrderDirect &&
      !!matchOrderDirect?.distributor?.allowStoreCreditCardPayments
    );
  }, [matchOrderDirect]);

  const PAYMENT_ITEMS = paymentFields(hidePayWithCredit, isAdvancePayment);

  const handleOpenDrawer = useCallback(
    (type) => {
      dispatch(showCurrentDrawerAction({ type, show: true }));
    },
    [dispatch]
  );

  const TOTAL_DATA = useMemo(() => {
    return [
      {
        title: "Ship to",
        firstLine: shippingInfo.topLine,
        secondLine: shippingInfo.bottomLine,
      },
      {
        title: "Bill to",
        firstLine: billingInfo.topLine,
        secondLine: billingInfo.bottomLine,
      },
      {
        title: "Delivery contact",
        showEdit: !!contacts.length && !!selectedContact,
        onClick: () => handleOpenDrawer(DRAWERS.CONTACT_DRAWER),
        name: "contact",
        element: (
          <Stack gap="4px">
            <Controller
              render={({ field }) => (
                <StyledSelect
                  labelProps={{ shrink: false }}
                  notched={false}
                  fullWidth
                  displayEmpty
                  fontSize="10px"
                  IconComponent={StyledArrowIcon}
                  color="#1C1C19"
                  height="22px"
                  formSx={{ width: "152px" }}
                  renderValue={(value) => getSelectPlaceholder(value)}
                  paperSx={{
                    ...cl.paperStyles,
                    ...classes.deliverySelectList,
                  }}
                  sx={{
                    ...cl.select,
                    ...classes.deliverySelect,
                  }}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    const selectedContact = contacts?.find(
                      (contact) => contact.id === event.target.value
                    );
                    setSelectedContact(selectedContact);
                  }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      setSelectedContact(null);
                      handleOpenDrawer(DRAWERS.CONTACT_DRAWER);
                    }}
                    sx={{
                      "& svg": {
                        mr: "5px",
                      },
                    }}
                  >
                    <OutlinedPlusIcon />
                    Add new
                  </MenuItem>
                  {contacts?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      <Typography noWrap fontSize="12px" fontWeight={500}>
                        {name}
                      </Typography>
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
              name="contactId"
              control={control}
            />
            <Stack>
              <Typography sx={classes.addressText}>
                {selectedContact?.email}
              </Typography>
              {!!selectedContact?.phone && (
                <Typography sx={classes.addressText}>
                  {setPhoneNumberMask(`${selectedContact?.phone}`)}
                </Typography>
              )}
            </Stack>
          </Stack>
        ),
      },
      {
        title: "Payment method",
        loading: cartData.cardLoading,
        name: "card",
        item: (!isAdvancePayment || !!cartData.card) && cartData.card,
        onClick: () =>
          handleOpenDrawer(
            creditCards?.length
              ? DRAWERS.SELECT_CARD_DRAWER
              : DRAWERS.ADD_CARD_DRAWER
          ),
        firstLine: (
          <Controller
            render={({ field }) => (
              <StyledSelect
                labelProps={{ shrink: false }}
                notched={false}
                fontSize="10px"
                IconComponent={StyledArrowIcon}
                color="#1C1C19"
                height="22px"
                formSx={{ width: "152px", mb: "4px" }}
                paperSx={{
                  ...cl.paperStyles,
                  ...classes.deliverySelectList,
                }}
                sx={{
                  ...cl.select,
                  ...classes.deliverySelect,
                }}
                {...field}
                onChange={(e) => {
                  setValue("payWith", e.target.value, { shouldDirty: true });
                  if (e.target.value === "terms") {
                    setValue("cardId", "");
                    return clearErrors("cardId");
                  }
                  setValue("cardId", cartData?.card?.id || "");
                }}
              >
                {PAYMENT_ITEMS.map(({ name, value }) => (
                  <MenuItem value={value} key={value} sx={{ width: "152px" }}>
                    {name}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            name="payWith"
            control={control}
          />
        ),
        secondLine: (
          <>
            {isAdvancePayment || formField.payWith === PAY_WITH.CREDIT ? (
              <>{cartData.card && <SelectedCard card={cartData.card} />}</>
            ) : (
              <Typography sx={classes.termText}>
                {approvedPaymentTerms?.name}
              </Typography>
            )}
          </>
        ),
        showEdit: false,
        showAdd:
          formField.payWith === PAY_WITH.CREDIT &&
          !cartData.card &&
          !cartData.cardLoading,
      },
    ];
  }, [
    PAYMENT_ITEMS,
    approvedPaymentTerms?.name,
    billingInfo.bottomLine,
    billingInfo.topLine,
    cartData.card,
    cartData.cardLoading,
    clearErrors,
    contacts,
    control,
    creditCards?.length,
    formField.payWith,
    getSelectPlaceholder,
    handleOpenDrawer,
    isAdvancePayment,
    selectedContact,
    setSelectedContact,
    setValue,
    shippingInfo.bottomLine,
    shippingInfo.topLine,
  ]);

  return (
    <Stack direction="column" gap="20px">
      {TOTAL_DATA.map((item, index) => (
        <MobileContactItem item key={index} {...item} />
      ))}
    </Stack>
  );
};

DeliveryCartBlock.propTypes = {
  creditCards: array,
};
