import { useCallback, useEffect, useState } from "react";
import { array, bool, func, number, object, shape } from "prop-types";
import { Box } from "@mui/material";
import { SettingsBlock } from "../../../../components";
import { CategoriesComponent } from "../../components";

import { SCROLL_LIMIT_DISTRIBUTORS } from "../../../../utils/constants";
import { MINIMUM_FILTERS } from "../../ProductsPage.constants";
import useStyles from "./styles";
import { SuppliersFilter } from "./components";
import { setSelectedSupplierAction } from "redux/actions/distributors";
import { useDispatch } from "react-redux";

const ProductsFilter = ({
  setSearchQuery,
  searchQuery,
  categoriesState,
  handleFetchCategories,
  getSortedSuppliersList,
  suppliersState,
  supplier,
  orderDirect,
  manufacturersState,
  getManufacturersList,
  resetManufacturersState,
  currentSupplier,
  paramsDistributors,
  paramsCategories,
  rawCountryOfOriginList,
  fetchCountryOfOrigin,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [distributorsLimit, setDistributorsLimit] = useState(
    SCROLL_LIMIT_DISTRIBUTORS * 2
  );

  useEffect(() => {
    if (
      !supplier &&
      manufacturersState?.list?.length &&
      suppliersState?.list?.length > 1
    ) {
      resetManufacturersState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState?.list?.length, supplier]);

  useEffect(() => {
    getSortedSuppliersList({ params: paramsDistributors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsCategories, paramsDistributors]);

  const handleFilterDistributors = useCallback(
    (item) => {
      const distr = orderDirect?.find((d) => d?.distributor?.id === item?.id);
      dispatch(setSelectedSupplierAction(distr?.distributor || item));
      setSearchQuery({
        ...searchQuery,
        distributor_ids: [item.id],
        countries_of_origin: [],
      });
      fetchCountryOfOrigin(item.id);
    },
    [dispatch, orderDirect, searchQuery, setSearchQuery, fetchCountryOfOrigin]
  );

  const handleFilterManufacturers = useCallback(
    (item) => {
      const ids = searchQuery.manufacturer_ids;
      if (ids.some((id) => id === item.id))
        return setSearchQuery({
          ...searchQuery,
          manufacturer_ids: ids.filter((id) => id !== item.id),
        });
      setSearchQuery({
        ...searchQuery,
        manufacturer_ids: [...ids, item.id],
      });
    },
    [searchQuery, setSearchQuery]
  );

  const handleFilterMinimum = useCallback(
    (item) => {
      setSearchQuery({
        ...searchQuery,
        min_order: item.value,
      });
    },
    [searchQuery, setSearchQuery]
  );

  const handleFetchDistributors = useCallback(() => {
    getSortedSuppliersList({
      params: { ...paramsDistributors, limit: distributorsLimit },
    });

    setDistributorsLimit((prev) => prev + SCROLL_LIMIT_DISTRIBUTORS);
  }, [distributorsLimit, getSortedSuppliersList, paramsDistributors]);

  const handleFetchManufacturers = useCallback(() => {
    getManufacturersList({
      page: manufacturersState.page + 1,
      // distributor_id: suppliersState.list?.[0]?.id,
      distributor_id: currentSupplier?.id,
    });
  }, [getManufacturersList, manufacturersState.page, currentSupplier]);

  const [countryOfOriginList, setCountryOfOriginList] = useState([]);

  const handleFilterCountryOfOrigin = useCallback(
    (item) => {
      const ids = searchQuery.countries_of_origin;
      if (ids.some((id) => id === item.id))
        return setSearchQuery({
          ...searchQuery,
          countries_of_origin: ids.filter((id) => id !== item.id),
        });
      setSearchQuery({
        ...searchQuery,
        countries_of_origin: [...ids, item.id],
      });
    },
    [searchQuery, setSearchQuery]
  );

  useEffect(() => {
    setCountryOfOriginList(
      rawCountryOfOriginList.filter(Boolean).map((country) => ({
        id: country,
        name: country,
      }))
    );
  }, [rawCountryOfOriginList]);

  return (
    <Box
      mt={
        supplier ||
        suppliersState.list.length === 1 ||
        !suppliersState.list.length
          ? 0
          : "-101px"
      }
      display="flex"
      flexDirection="column"
      gap="22px"
      width="292px"
      minWidth="292px"
    >
      {suppliersState.list.length > 1 && !supplier ? (
        <SuppliersFilter
          items={suppliersState.list}
          handleFetch={handleFetchDistributors}
          itemsCount={suppliersState.count}
          handleChange={handleFilterDistributors}
        />
      ) : null}

      <CategoriesComponent
        loading={categoriesState.loading}
        childrenlist={categoriesState.childrenlist}
        parentsList={categoriesState.parentsList}
        category={categoriesState.currentCategory}
        handleFetchCategories={handleFetchCategories}
      />

      {suppliersState.list.length > 1 ? (
        <SettingsBlock
          title="Minimum Order"
          items={MINIMUM_FILTERS}
          handleChange={handleFilterMinimum}
        />
      ) : null}
      {manufacturersState.list?.length > 1 && (
        <SettingsBlock
          title="Brand"
          items={manufacturersState.list}
          itemsCount={manufacturersState.count}
          handleChange={handleFilterManufacturers}
          multiselect
          handleFetch={handleFetchManufacturers}
          hideCounter
          checkedItemsIds={searchQuery?.manufacturer_ids}
          classNames={{ items: classes.brandItems }}
        />
      )}

      {countryOfOriginList?.length > 1 && !suppliersState?.loading && (
        <SettingsBlock
          title="Country of Origin"
          items={[
            {
              id: "No country of origin",
              name: "No country of origin",
            },
            ...countryOfOriginList,
          ]}
          multiselect
          hideCounter
          checkedItemsIds={searchQuery?.countries_of_origin}
          handleChange={handleFilterCountryOfOrigin}
        />
      )}
    </Box>
  );
};

ProductsFilter.propTypes = {
  setSearchQuery: func,
  searchQuery: object,
  categoriesState: object,
  handleFetchCategories: func,
  getSortedSuppliersList: func,
  suppliersState: shape({
    loading: bool,
    list: array,
    count: number,
  }),
  setSortingVal: func,
  supplier: object,
  orderDirect: array,
  manufacturersState: object,
  currentSupplier: object,
  paramsDistributors: object,
  paramsCategories: object,
  getManufacturersList: func,
  resetManufacturersState: func,
  rawCountryOfOriginList: array,
  fetchCountryOfOrigin: func,
};

export default ProductsFilter;
