import { CategoryChip } from "components";
import { useCallback, useEffect, useMemo, useState } from "react";

const DEFAULT_FILTER_DATA = {
  categories: {
    show: false,
    values: [],
  },
  brands: {
    show: false,
    values: [],
  },
  countries: {
    show: false,
    values: [],
  },
};

const DEFAULT_CHIP_DATA = {
  categories: [],
  brands: [],
  countries: [],
};

export const FILTER_TABS = ["categories", "brands", "countries"];

export const useProductFilter = ({
  brandsList = [],
  categoriesList = [],
  countriesList = [],
  handleApplyFilter,
  chipData,
}) => {
  const [checkedBrands, setCheckedBrands] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState({});
  const [checkedCountries, setCheckedCountries] = useState([]);

  const handleStateIds = (state) => state.map(({ id }) => id);

  const FILTER_DATA = useMemo(() => {
    return {
      categories: {
        show: !!categoriesList?.length,
        values: checkedCategories?.id,
      },
      brands: {
        show: !!brandsList?.length,
        values: handleStateIds(checkedBrands),
      },
      countries: {
        show: countriesList?.length > 1,
        values: handleStateIds(checkedCountries),
      },
    };
  }, [
    brandsList?.length,
    categoriesList?.length,
    checkedBrands,
    checkedCategories?.id,
    checkedCountries,
    countriesList?.length,
  ]);

  useEffect(() => {
    setCheckedBrands(chipData.brands);
    setCheckedCategories(
      chipData.categories.length ? chipData.categories[0] : {}
    );
    setCheckedCountries(chipData.countries);
  }, [chipData]);

  const onSave = () => {
    handleApplyFilter(FILTER_DATA, {
      categories: Object.keys(checkedCategories).length
        ? [checkedCategories]
        : [],
      brands: checkedBrands,
      countries: checkedCountries,
    });
  };

  const onStateClear = () => {
    setCheckedBrands([]);
    setCheckedCategories({});
    setCheckedCountries([]);

    if (
      !!checkedBrands.length ||
      !!checkedCountries.length ||
      !!Object.keys(checkedCategories).length
    ) {
      handleApplyFilter(DEFAULT_FILTER_DATA, DEFAULT_CHIP_DATA);
    }
  };

  const currentFilterElement = useMemo(() => {
    return {
      categories: {
        list: categoriesList,
        Item: CategoryChip,
        setState: setCheckedCategories,
        defaultValue: {},
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({ state: checkedCategories, id: item.id }),
          onClick: (item) =>
            handleCheckItem({
              setState: setCheckedCategories,
              item,
              state: checkedCategories,
            }),
        }),
      },
      brands: {
        list: brandsList,
        Item: CategoryChip,
        setState: setCheckedBrands,
        defaultValue: [],
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: checkedBrands,
            id: item.id,
            isMultiple: true,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: setCheckedBrands,
              item,
              state: checkedBrands,
              isMultiple: true,
            }),
        }),
      },
      countries: {
        list: [
          { id: "No country of origin", name: "No country of origin" },
          ...countriesList,
        ],
        Item: CategoryChip,
        setState: setCheckedCountries,
        defaultValue: [],
        getProps: (item, handleCheckItem, handleIsActive) => ({
          title: item.name,
          isActive: handleIsActive({
            state: checkedCountries,
            id: item.id,
            isMultiple: true,
          }),
          onClick: (item) =>
            handleCheckItem({
              setState: setCheckedCountries,
              item,
              state: checkedCountries,
              isMultiple: true,
            }),
        }),
      },
    };
  }, [
    categoriesList,
    brandsList,
    countriesList,
    checkedCategories,
    checkedBrands,
    checkedCountries,
  ]);

  const disabledActionBtn =
    !checkedBrands.length &&
    !checkedCountries.length &&
    !Object.keys(checkedCategories).length;

  const handleAppliedFilters = useCallback(() => {
    let count = 0;
    for (let key in chipData) {
      if (Array.isArray(chipData[key]) && chipData[key].length > 0) {
        count++;
      }
    }
    return count;
  }, [chipData]);

  const filterCount = useMemo(
    () => handleAppliedFilters(),
    [handleAppliedFilters]
  );

  return {
    FILTER_DATA,
    FILTER_TABS,
    onSave,
    onStateClear,
    currentFilterElement,
    disabledActionBtn,
    filterCount,
  };
};
