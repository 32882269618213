import { useCallback, useState, useEffect, useMemo, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Badge, Box, Stack, Typography } from "@mui/material";
import {
  MobileChipList,
  MobileProductList,
  ProductCard,
  ProductsFilter,
  SearchBlock,
} from "./components";

import { resetPaginationAction } from "redux/actions/settings";

import { useCategories } from "./useCategories";
import { ProductsContext, useProducts } from "./useProducts";
import { FilterDrawer, FloatingCartBtn, Loader } from "components";
import RejectScreen from "./components/RejectScreen/RejectScreen";
import { ConfirmContext, SuppliersContext } from "pages/MasterPage/MasterPage";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { successMiddle } from "helpers/notifications";
import RegisterFreeDialog from "./components/RegisterFreeDialog/RegisterFreeDialog";
import { ArrowNextIcon, CartBtnIcon, HomeIcon } from "components/Icons";
import { useMediaDevice } from "hooks/useMediaDevice";
import { setSelectedSupplierAction } from "redux/actions/distributors";
import { useManufacturers } from "./useManufacturers";

import useStyles, { classes } from "./styles";
import { SCROLL_LIMIT_DISTRIBUTORS } from "utils/constants";
import { useProductFilter } from "./useProductFilter";
import { selectedDistributorAction } from "redux/actions/drafts";
import { useCartCounter } from "./useCartCounter";
import { useCountryOfOriginActions } from "hooks/useCountryOfOriginActions";
import { RequestWholesaleDrawer } from "components/RequestWholesaleDialog/RequestWholesaleDrawer/RequestWholesaleDrawer";

const ProductsPage = () => {
  const className = useStyles();
  const navigate = useNavigate();
  const { isMobile } = useMediaDevice();

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const supplier = useSelector(
    ({ distributors }) => distributors.selectedSupplier
  );

  const { loading: draftsLoading } = useSelector(({ drafts }) => drafts);

  const dispatch = useDispatch();

  const isNotAllowToViewProducts =
    isMobile && currentUser?.orderDirect?.length > 1 && !supplier;

  useEffect(() => {
    if (isMobile && currentUser?.orderDirect?.length > 1 && !supplier) {
      navigate("/suppliers");
    }
  }, [currentUser?.orderDirect?.length, isMobile, navigate, supplier]);

  const { orderDirect } = currentUser || {};
  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const { appWithoutLogInState, setConfirmState } = useContext(ConfirmContext);

  const { state } = useLocation();
  const [searchQuery, setSearchQuery] = useState({
    distributor_ids: [],
    manufacturer_ids: [],
    min_order: "",
    countries_of_origin: [],
  });

  const paramsDistributors = useMemo(
    () => ({
      limit: SCROLL_LIMIT_DISTRIBUTORS,
    }),
    []
  );

  const paramsCategories = useMemo(
    () => ({
      sort_by_count: "desc",
      limit: 10,
      include_product_count: true,
    }),
    []
  );

  const {
    state: categoriesState,
    handleFetchCategories,
    handleFlatCategories,
  } = useCategories({
    distributorId: appWithoutLogInState?.distributor?.id || supplier?.id,
    manufacturer_ids: searchQuery?.manufacturer_ids,
  });
  const { chosenCategoryId } = categoriesState;

  const { handleFetch: fetchCountryOfOrigin, list: rawCountryOfOriginList } =
    useCountryOfOriginActions({
      params: {
        sort_clients_countries_first: true,
        sort_by_name: "asc",
        used_on_products: true,
      },
    });

  useEffect(() => {
    if (isShowProductsWithoutLogIn) return;
    dispatch(selectedDistributorAction(supplier));
    fetchCountryOfOrigin(supplier?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier, dispatch]);

  const [chipData, setChipData] = useState({
    categories: [],
    brands: [],
    countries: [],
  });
  const [searchVal, setSearchVal] = useState("");
  const [sortingVal, setSortingVal] = useState({ sort_by_name: "asc" });

  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);

  const { suppliersState, getSortedSuppliersList } =
    useContext(SuppliersContext);

  useEffect(() => {
    handleFetchCategories(state?.filter?.category?.id ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery?.manufacturer_ids]);

  useEffect(() => {
    if (
      state?.filter?.supplier?.id &&
      suppliersState?.list?.length > 1 &&
      !state?.filter?.category?.id
    ) {
      setSearchQuery((prev) => ({
        ...prev,
        distributor_ids: [state?.filter?.supplier?.id],
      }));
    }

    if (state?.filter?.category?.id) {
      if (state?.filter?.supplier?.id && suppliersState?.list?.length > 1) {
        setSearchQuery((prev) => ({
          ...prev,
          distributor_ids: [state?.filter?.supplier?.id],
        }));
      }
      handleFetchCategories(state?.filter?.category?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state?.filter?.category,
    state?.filter?.supplier?.id,
    suppliersState?.list?.length,
  ]);

  useEffect(() => {
    setConfirmState((prev) => ({
      ...prev,
      isOpen: false,
      onConfirm: () => {},
      supplier,
    }));
  }, [setConfirmState, supplier]);

  const { productsState, handleSearch } = useProducts({
    chosenCategoryId,
    searchVal,
    sortingVal,
    searchQuery,
    distributorId: appWithoutLogInState?.distributor?.id || supplier?.id,
  });

  useEffect(() => {
    return () => dispatch(resetPaginationAction());
  }, [dispatch]);

  const handleOpenProductProfile = useCallback(
    (data) => {
      const product = data?.parentProduct || data;

      if (!product) return;

      if (isShowProductsWithoutLogIn) {
        setRegisterDialogOpen(true);
        return;
      }

      if (
        !product.childProducts ||
        (!product.childProducts.some(
          (childProduct) => childProduct.wholesalePrice !== null
        ) &&
          product.wholesalePrice === null)
      ) {
        successMiddle("Pending for order direct request approval", {
          position: "bottom-center",
        });
        return;
      }

      navigate(`/products/${product.id}`, { state: { product } });
    },
    [isShowProductsWithoutLogIn, navigate]
  );

  const { cartCounter, disabledCartBtn, currentOrderDirect, showRejectScreen } =
    useCartCounter();

  const supplierApproved = useCallback(
    (product) => {
      const { distributor } = product || {};

      if (orderDirect?.length === 1)
        return orderDirect?.[0]?.status === "APPROVED";

      const currentProductSupplier = orderDirect?.find(
        (od) => od?.distributor?.id === distributor?.id
      );

      return currentProductSupplier?.status === "APPROVED";
    },
    [orderDirect]
  );

  const showRetailPrice = useCallback(
    (product) => {
      const { distributor } = product || {};
      if (orderDirect?.length === 1)
        return suppliersState?.list?.[0]?.showRetailPrice;

      const currentProductSupplier = suppliersState?.list?.find(
        (od) => od?.id === distributor?.id
      );

      return currentProductSupplier?.showRetailPrice;
    },
    [orderDirect?.length, suppliersState?.list]
  );

  const productsContent = useMemo(() => {
    if (showRejectScreen) return;
    if (productsState?.list?.length) {
      if (isMobile)
        return (
          <MobileProductList
            {...{
              chipData,
              supplierApproved,
              showRetailPrice,
              productsState,
              handleSearch,
              hasProductsInCart: !!cartCounter,
              handleOpenProductProfile,
            }}
          />
        );

      return (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fill, minmax(167px, 1fr))",
              sm: "repeat(auto-fill, minmax(249px, 1fr))",
            },
            justifyContent: "center",
            gap: { xs: "9px", sm: "15px" },
          }}
        >
          {productsState.list.map((product) => {
            return (
              <ProductCard
                key={product?.id}
                product={product}
                handleOpenProductProfile={handleOpenProductProfile}
                suppliersCount={suppliersState.count}
                filteredBySupplier={!!supplier}
                supplierApproved={supplierApproved(product)}
                showRetailPrice={showRetailPrice(product)}
              />
            );
          })}
        </Box>
      );
    } else {
      return (
        <Box
          height="calc(100vh - 222px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>No products found</Typography>
        </Box>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showRejectScreen,
    orderDirect,
    productsState,
    isMobile,
    supplierApproved,
    showRetailPrice,
    handleOpenProductProfile,
    suppliersState.count,
    supplier,
    cartCounter,
  ]);

  const handleNavigateToRegistration = () => {
    navigate(`/orderdirect/${appWithoutLogInState?.distributor?.storeName}`);
  };

  const handleApplyMobileFilter = (state, chipState) => {
    const { brands, categories, countries } = state;
    setChipData(chipState);

    setSearchQuery((prev) => ({
      ...prev,
      //...(brands.values.length && {
      manufacturer_ids: brands.values.length ? brands.values : [],
      //}),
      category_id: categories?.values ? categories?.values : null,
      countries_of_origin: countries?.values ? countries?.values : [],
    }));
  };

  const getCategoryIds = (state) => {
    if (!state.length) return [];

    return state.map(({ id }) => id);
  };

  const handleDeleteChip = (category, chipId) => {
    const filteredState = chipData[category].filter(({ id }) => id !== chipId);
    const ids = getCategoryIds(
      category === "categories" ? chipData.brands : filteredState
    );
    setChipData((prev) => ({ ...prev, [category]: filteredState }));

    setSearchQuery((prev) => ({
      ...prev,
      manufacturer_ids: ids,
      ...(category === "categories" && { category_id: null }),
    }));
  };

  const { manufacturersState, getManufacturersList, resetManufacturersState } =
    useManufacturers();

  const productsContextMemo = useMemo(
    () => ({
      searchVal,
      setSearchVal,
      searchLoading: productsState.loading,
      productsCount: productsState.count,
    }),
    [productsState.count, productsState.loading, searchVal]
  );

  useEffect(() => {
    getSortedSuppliersList({ params: paramsDistributors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsCategories, paramsDistributors]);

  const currentSupplier = useMemo(() => {
    return suppliersState?.list?.length > 1
      ? supplier
      : suppliersState?.list?.[0];
  }, [supplier, suppliersState?.list]);

  useEffect(() => {
    getManufacturersList({
      distributor_id: currentSupplier?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSupplier?.id]);

  const flatCategoriesList = useMemo(
    () => handleFlatCategories(),
    [handleFlatCategories]
  );

  const {
    FILTER_DATA,
    FILTER_TABS,
    onSave,
    filterCount,
    disabledActionBtn,
    onStateClear,
    currentFilterElement,
  } = useProductFilter({
    brandsList: manufacturersState.list,
    categoriesList: flatCategoriesList,
    countriesList: rawCountryOfOriginList.filter(Boolean).map((country) => ({
      id: country,
      name: country,
    })),
    handleApplyFilter: handleApplyMobileFilter,
    chipData,
  });

  if (isNotAllowToViewProducts)
    return (
      <Stack
        height="calc(100svh - 64px)"
        alignItems="center"
        justifyContent="center"
      >
        <Loader isLoading={productsState.loading} />
      </Stack>
    );

  return (
    <ProductsContext.Provider value={productsContextMemo}>
      {!!cartCounter && isMobile && !showRejectScreen && (
        <FloatingCartBtn
          title="View cart"
          url="/cart"
          disabled={draftsLoading || disabledCartBtn}
          icon={
            <Badge badgeContent={cartCounter} sx={classes.badgeIcon}>
              <CartBtnIcon />
            </Badge>
          }
        />
      )}
      <FilterDrawer
        {...{
          FILTER_DATA,
          FILTER_TABS,
          onSave,
          onStateClear,
          disabledActionBtn,
          currentFilterElement,
        }}
      />
      {!isMobile && (
        <>
          {!!supplier && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "44px",
                mb: -3.25,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box
                  fontSize={16}
                  fontWeight={400}
                  color="#000"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <Box
                    sx={{
                      color: "#000",
                      textDecoration: "none",
                      zIndex: 1,
                    }}
                    component={Link}
                    to="/"
                    onClick={() => {
                      dispatch(setSelectedSupplierAction(null));
                      setSearchQuery({
                        ...searchQuery,
                        distributor_ids: [],
                        countries_of_origin: [],
                      });
                    }}
                  >
                    <HomeIcon />
                  </Box>
                  <ArrowNextIcon color="#000" width={5.21} height={9.11} />
                  <Typography sx={{ color: "#707070" }}>
                    {supplier?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
      <Box pt={{ xs: 0, sm: "26px" }} height="100%">
        {isShowProductsWithoutLogIn && (
          <>
            {isMobile ? (
              <RequestWholesaleDrawer
                open={registerDialogOpen}
                {...{
                  handleCloseOrderDirectAccess: () =>
                    setRegisterDialogOpen(false),
                  brandLogo: appWithoutLogInState?.distributor?.brandLogo,
                  shippingAddress:
                    appWithoutLogInState?.distributor?.shippingAddress,
                  handleRequestWholesale: handleNavigateToRegistration,
                  classes: className,
                  hideRequestPaymentTerms: true,
                  isRegister: true,
                  name: appWithoutLogInState?.distributor?.name,
                }}
              />
            ) : (
              <RegisterFreeDialog
                open={registerDialogOpen}
                onClose={() => setRegisterDialogOpen(false)}
                distributor={appWithoutLogInState?.distributor}
              />
            )}
          </>
        )}

        {((isMobile && !showRejectScreen) || !isMobile) && (
          <SearchBlock
            distr={appWithoutLogInState?.distributor || supplier}
            setSortingVal={setSortingVal}
            sortingVal={sortingVal}
            chipData={chipData}
            filterCount={filterCount}
          />
        )}

        <MobileChipList {...{ filterCount, chipData, handleDeleteChip }} />
        {showRejectScreen ? (
          <RejectScreen reject={currentOrderDirect[0]} />
        ) : (
          <Box
            display="flex"
            gap="30px"
            height="100%"
            mt={{ xs: "10px", sm: "21px" }}
          >
            {!showRejectScreen && !isMobile && (
              <ProductsFilter
                flex="1 1 13%"
                {...{
                  setSearchQuery,
                  searchQuery,
                  categoriesState,
                  handleFetchCategories,
                  getSortedSuppliersList,
                  suppliersState,
                  setSortingVal,
                  supplier,
                  orderDirect,
                  manufacturersState,
                  getManufacturersList,
                  resetManufacturersState,
                  currentSupplier,
                  paramsCategories,
                  paramsDistributors,
                  rawCountryOfOriginList,
                  fetchCountryOfOrigin,
                }}
              />
            )}
            <Box flex={{ xs: "1 1 100%", sm: "1 1 87%" }}>
              {productsState.loading ? (
                <Loader isLoading={productsState.loading} />
              ) : (
                <Box>
                  {draftsLoading && !isShowProductsWithoutLogIn && (
                    <Loader isLoading={draftsLoading} />
                  )}
                  {productsContent}
                  {!isMobile && !showRejectScreen && (
                    <Typography
                      sx={{
                        fontSize: "17px",
                        color: "#717171",
                        mt: "25px",
                        minHeight: "32px",
                      }}
                    >
                      Showing {productsState.count} products
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </ProductsContext.Provider>
  );
};

export default ProductsPage;
